import axios from "axios";

export const auth0Config = {
    client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
};

export const reposWebApi = axios.create({
    // baseURL: 'https://staging.reposenergy.com/api/web/v1'
    baseURL:'https://drf.reposenergy.com/api/web/v1'
    // baseURL: 'https://develop.reposenergy.com/api/web/v1',

});

export const reposAndroidApi = axios.create({
    // baseURL: 'https://staging.reposenergy.com/api/web/v1'
    baseURL:'https://drf.reposenergy.com/api/web/v1'
    // baseURL: 'https://develop.reposenergy.com/api/android/v1',

});

export const fileUploadServiceApi = axios.create({
    baseURL: "http://13.127.225.8:8003/v1/upload",
    // baseURL: 'http://9bdba7f2891e.ngrok.io/v1/upload'
});

export const appVersion = "2.0";

// export const DownloadAgreement = "https://staging.reposenergy.com/api/web/v1/";
export const DownloadAgreement = "https://drf.reposenergy.com/api/web/v1/";

// staging
// export const client_id = 'JF9QFcSRNGi8BpH1724CmSoSv9MsXxey32BurKLf';
// export const client_secret = 'FFadtW6F9W95Vcf01MCGkHkIi0a8xBI9auUecCqTTjtsBZ0yAnyFGFwPZ5IsC1KHuawSNrUW87aOr0M8mJEeZEwOW5ptv5oYC5KlM3J6GpKS8j1cQazP8QmHQSLXP51B';

// live
export const client_id = 'eTcIPfEYSzmruVEoV3TmqwlqWW3QEYTOrdX6cvoh';
export const client_secret = '836anIBgI8zpORngLyYHJvLMU91mgZexSbhghdn3vbSMULiXvn03yLvJDprKNpxI6v1Qb77eR3b6ydQFnPiJnm7vP5458i1TbxM5CxbHfCuNJHFX9SFB2EORb3gD9kY9';

