import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
// Utilities
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';

// Material Components
import {Box, Container, Drawer, Hidden} from '@mui/material';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'left',
    },
    mobileDrawer: {
        width: 300
    },
    desktopDrawer: {
        backgroundColor: "#f5f5f5"
    },
    avatar: {
        cursor: 'pointer',
        width: 64,
        height: 64
    },
    boxSize: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        padding: "24px",
        gap: '16px',
        width: "268px",
        height: "165px",
        background: "#FFFFFF",
        borderRadius: "10px",
    },
    scaleDown: {
        transform: 'scale(.95)'
    },
    height: {
        height: '45px'
    },
    radius: {
        borderRadius: '20px',
        boxShadow: '-8px 13px 8px 4px rgb(0 0 0 / 37%)',
    },
    underlinedBtnText: {
        transform: 'translateY(-2px)',
        position: 'relative',
        textTransform: 'capitalize',
        fontSize: '.9rem',
        '&::after': {
            content: "''",
            position: 'absolute',
            bottom: 0,
            left: 0,
            backgroundColor: 'currentColor',
            height: 1,
            width: '100%'
        }
    },
    marginRightAuto: {
        marginRight: 'auto'
    },
    drawer: {
        width: '100%',
        maxWidth: 560
    },
    topLabel: {
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "16px",
        lineHeight: "16px",
        color: "#161616",
        width: "102px",
        height: "16px",
    },
    mainLabel: {
        fontSize: "24px",
        fontWeight: 900,
        lineHeight: "24px",
        background: "-webkit-linear-gradient(53.09deg, #122E91 6.51%, #02A29F 80.84%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent"
    },
    label2: {
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "14px",
        fontStyle: "normal",
        color: '#5C5C5C'
    },
}));

const SideBar = ({onMobileClose, openMobile}) => {

    const classes = useStyles();
    const location = useLocation();
    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const content = (
        <div style={{backgroundColor: "#f5f5f5"}}>
            <Container className={classes.root}>
                <Box height="100%" display="flex" flexDirection="column" backgroundColor="#f5f5f5">
                    <Box mt={3} mb={8}>
                    </Box>
                    <Box mt={3}>
                        <div>
                        <a href="/addReferral" style={{textDecoration: 'none'}}>
                            <img
                                alt="Earn Big"
                                src="/static/images/banner.jpg"
                                style={{width: '250px', borderRadius: "10px", height: '240px'}}
                            />
                        </a>
                        </div>
                    </Box>
                    <Box mt={3}>
                        <div>
                        <a href="/addReferral" style={{textDecoration: 'none'}}>
                            <img
                             alt="referral"
                             src="/static/images/referral_banner1.svg"
                                style={{width: '250px', borderRadius: "10px", height: '240px'}}
                            />
                        </a>
                        </div>
                    </Box>
                    <Box mt={3}>
                        <div>
                      
                            <img
                                 alt="Carbon Nutral Future"
                                 src="/static/images/carbonNutralFuture.svg"
                                style={{width: '250px', borderRadius: "10px", height: '240px'}}
                            />
                    
                        </div>
                    </Box>
                </Box>
            </Container>
        </div>
    )
    return (
        <>
            {/*<Hidden lgUp>*/}
            {/*    <Drawer*/}
            {/*        anchor={"right"}*/}
            {/*        variant="temporary"*/}
            {/*        classes={{paper: classes.mobileDrawer}}*/}
            {/*        onClose={onMobileClose}*/}
            {/*        open={openMobile}*/}
            {/*    >*/}
            {/*        {content}*/}
            {/*    </Drawer>*/}
            {/*</Hidden>*/}
            <Hidden lgDown>
                <Drawer
                    anchor={"right"}
                    variant="persistent"
                    classes={{paper: classes.desktopDrawer}}
                    open
                    style={{zIndex: '-1'}}
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
    )
}

SideBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
}
export default SideBar

